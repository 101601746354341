import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";

import {AdmissionList_Item_Memo} from "./Item/AdmissionList_Item";
import AdmissionList_Tabs from "./Tabs/AdmissionList_Tabs";
import useSortableData from "../../utils/sortableData";
import useFilteredData from "../../utils/subfilteredData";
import AdmissionList_Search from "./Tabs/AdmissionList_Search";
import useAdmissionListNavigation from "../../utils/admissionListNavigation";
import FlashMessages from "../FlashMessages/FlashMessages";
import AdmissionListMessages from "../AdmissionListMessages";
import AdmissionList_Bulk from "./AdmissionList_Bulk";
import AdmissionList_SubFilter from "./SubFilter/AdmissionList_SubFilter";
import AdmissionList_Pager from "./AdmissionList_Pager";
import AdmissionList_SortHeaders from "./AdmissionList_SortHeaders";

// This code is a mess. From what I see - I may never clean up it :(
// If you are trying to sort it out - sorry for this code.
// Today I need to complete weekly change set amount. Again.
// Permanent deadlines, permanent context switching with tons of the minor tasks in completely different areas.
// now I am burned out and I need to restore.
//
// Be careful, do not repeat my mistakes.

const AdmissionList = () => {
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();
    const {admissions, sortColumn, sortMode, totalPages} = useSelector(state => state.admissionList);
    const {filteredItems} = useFilteredData(admissions);
    const { items, requestSort, getClassNamesFor } = useSortableData(pushAdmissionsListUrl, filteredItems, {key: sortColumn, direction: sortMode});

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    const devIsPagerDisabled = (!totalPages) || (totalPages === 1)  || (totalPages === "1");

    console.log('AdmissionList render', items.length);

    const renderRow = (admission, key) => <AdmissionList_Item_Memo key={key} admission={admission}/>

    return (
        <main className="main">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <FlashMessages />
                        <h1 className="h2">Admissions List</h1>
                        <AdmissionListMessages />
                        <div className="table-holder bg-white rounded shadow-sm border">
                            <AdmissionList_Tabs />
                            {devIsPagerDisabled && <AdmissionList_SubFilter />}
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel"
                                     aria-labelledby="tab1">
                                    <AdmissionList_Search />
                                    <table className="table procedure-list-table" cellSpacing="0" width="100%">
                                        <AdmissionList_SortHeaders sortingEnabled={devIsPagerDisabled} _requestSort={requestSort} _getClassNamesFor={getClassNamesFor}/>
                                        <tbody>
                                        {items.map(renderRow)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AdmissionList_Bulk />
                    <AdmissionList_Pager />
                </div>
            </div>
        </main>
    );
}
AdmissionList.whyDidYouRender = true;

export default AdmissionList;