import React, { useState } from 'react';
import { useSelector } from "react-redux";
import logo from '../../../assets/images/logo.svg';
import axios from 'axios';

function ChangePassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [alertClass, setAlertClass] = useState(''); // Add state to control alert class
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const idToken = useSelector(state => state.auth.idToken);

    const isPasswordValid = () => {
        const hasValidLength = newPassword.length >= 14;
        const hasNumber = /\d/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasNoSpaces = !/\s/.test(newPassword);

        return hasValidLength && hasNumber && hasSpecialChar && hasNoSpaces;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isPasswordValid()) {
            setMessage('Your password is weak. It must contain at least 10 characters, 1 number, and 1 special character.');
            setAlertClass('alert-danger');
        } else if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            setAlertClass('alert-danger');
        } else {
            try {
                const response = await axios.post(
                    '/change-password',
                    {
                        password: newPassword,
                        confirm_password: confirmPassword,
                    },
                    {
                        headers: {
                            Authorization: `${idToken}`, // Use idToken for authorization
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        },
                    }
                );

                if (response) {
                    setMessage('Password successfully changed!');
                    setAlertClass('alert-success');
                    setNewPassword('');
                    setConfirmPassword('');
                }
            } catch (error) {
                setMessage('An error occurred while changing the password.');
                setAlertClass('alert-danger');
                console.error('Error changing password:', error);
            }
        }
    };

    const togglePasswordVisibility = (field) => {
        if (field === 'new') {
            setShowNewPassword(!showNewPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center min-vh-100 py-5">
            <div className="card shadow custom-card w-100" style={{ maxWidth: '400px' }}>
                <div className="card-body">
                    <div className="text-center mb-4">
                        <img src={logo} alt="admission" className="img-fluid custom-logo" />
                    </div>
                    <h2 className="card-title text-center mb-3">Change Your Password</h2>
                    <p className="text-center text-muted mb-4">Enter a new password below to change your password.</p>
                    {message && <div className={`alert ${alertClass}`} role="alert">{message}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                className="form-control custom-input"
                                placeholder="New password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <button
                                type="button"
                                className="btn btn-outline-secondary custom-eye-btn"
                                onClick={() => togglePasswordVisibility('new')}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                            </button>
                        </div>
                        <div className="input-group mb-3">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control"
                                placeholder="Re-enter new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => togglePasswordVisibility('confirm')}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                            </button>
                        </div>
                        {newPassword && (
                            <div className="mb-3">
                                <div className={`p-2 border rounded ${isPasswordValid() ? 'border-success' : 'border-danger'} bg-light`}>
                                    <p className="text-center mb-0">
                                        {isPasswordValid() ? "Your password is strong." : "Your password is weak."}
                                    </p>
                                </div>
                            </div>
                        )}
                        <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={!isPasswordValid() || newPassword !== confirmPassword}
                            style={{
                                backgroundColor: isPasswordValid() ? '#007bff' : '#6c757d',
                                borderColor: isPasswordValid() ? '#007bff' : '#6c757d'
                            }}
                        >
                            Change password
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
