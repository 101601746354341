import React from "react";
import {useSelector} from "react-redux";
import { useState } from 'react';
import EventLogs from "../EventLogs/EventLogs";
import AdmissionProgress from "../AdmissionsList/AdmissionProgress";
import Admission_Pdf from "./Admission_Pdf";
import useAdmissionListNavigation from "../../utils/admissionListNavigation";
import Admission_Funding from "./Admission_Funding";
import FlashMessages from "../FlashMessages/FlashMessages";
import ResendSmsBtn from "./ResendSmsBtn";
import WebPasSyncBtn from "./WebPasSyncBtn";
import SendMessageBtn from "./SendMessageBtn";
import Admission_DevInfo from "./Admission_DevInfo";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import ImpersonateBtn from "./ImpersonateBtn";
import Admission_DevInfo_OechCheckData from "./Admission_DevInfo_OechCheckData";
import EditHospitalPatientBtn from "./EditHospitalPatientBtn";
import AdmissionList_Item_MoreDrop from "../AdmissionsList/Item/AdmissionList_Item_MoreDrop";
import Admission_MoreDrop from "./Admission_MoreDrop";
import JMPH_AltNumber from "../CustomLogic/JMPH_AltNumber";
import DocumentList from './Admission_DevInfo_Document'

const Admission = () => {
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();
    const goToAdmissionsList = () => pushAdmissionsListUrl();
    const {manager_patientImpersonate_enable} = useSelector(state => state.auth);

    const renderFundingColumn = () => {
        if (admission.hospital.oec_enableManagerUI) {
            return (
                <div className="col-4">
                    <Admission_Funding admission={admission} />
                </div>
            )
        }
        return null;
    }
  
    const renderProgressBlocks = () => {
        if (!admission.cancelled) {
            return (
                <div className="row">
                    {renderFundingColumn()}
                    <div className="col-8">
                        <h2 className="h4">Pre-ad Document Set</h2>
                        <div className="funding-box bg-white rounded shadow-sm border p-3 mb-5 status-info-admission ">
                            <AdmissionProgress admission={admission} />
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    const admission = useSelector(state => state.admission.admission);
    return (
        <main className="main">
            <div className="container">
                <FlashMessages admission={admission}/>
                <div className="row">
                    <div className="col-3">
                        <div className="heading">
                            <h1 className="h2"><i className="icon ico-arrow-left pointer" onClick={goToAdmissionsList}/> <span>{admission.title} {admission.surname}, {admission.name}</span></h1>
                        </div>
                    </div>
                    <div className="col-9">
                        {!admission.mobilePhone &&
                        <div className="alert alert-danger" role="alert">
                            Mobile phone is not set
                        </div>
                        }
                        {admission.sms_opt_out &&
                            <div className="alert alert-danger" role="alert">
                                Patient opted out of messages (set in PAS)
                            </div>
                        }
                        <div className="heading-info d-flex justify-content-end">
                            <div className="btns">
                                <WebPasSyncBtn />
                                <Admission_Pdf admission={admission} />
                                {/*<ResendSmsBtn />*/}
                                {admission.hospitalPatient && <EditHospitalPatientBtn />}
                                {admission.hospital.messages_enableManagerUI && <SendMessageBtn />}
                                {manager_patientImpersonate_enable && <ImpersonateBtn admission={admission}/>}
                                <Admission_MoreDrop admission={admission} />
                            </div>
                            {/* TODO: Pager */}
                            {/*<div className="paging-info">*/}
                            {/*    <span className="paging">2 of 1,324</span>*/}
                            {/*    <div className="btn-group">*/}
                            {/*        <button className="btn btn-sm btn-white btn-prev-page">*/}
                            {/*            <i className="icon ico-left-arrow-caret"/>*/}
                            {/*        </button>*/}
                            {/*        <button className="btn btn-sm btn-white btn-next-page">*/}
                            {/*            <i className="icon ico-right-arrow-caret"/>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <aside className="sidebar bg-white rounded shadow-sm border">
                            <ul className="person-info-list">
                                <Admission_DevInfo />
                                {/*<li>*/}
                                {/*    <strong className="title">Medicare data check</strong>*/}
                                {/*    <dl className="person-list">*/}
                                {/*        <dt>Done, last checked at</dt>*/}
                                {/*        <dd> 2022-01-08 17:24:52 (OPV)</dd>*/}
                                {/*    </dl>*/}
                                {/*</li>*/}
                                <li>
                                    <strong className="title">Admission Info</strong>
                                    <dl className="person-list">
                                        <UrDt admission={admission}/>
                                        <AdmDt admission={admission}/>
                                        <JMPH_AltNumber admission={admission}/>
                                        <dt>Date</dt>
                                        <dd>{admission.admissionDate} {admission.admissionTime}</dd>
                                        <dt>Hospital</dt>
                                        <dd>{admission.hospitalTitle}</dd>
                                    </dl>
                                </li>
                                {admission.primaryProcedureItemDescription && <li>
                                    <strong className="title">Procedure</strong>
                                    <strong className="sub-heading d-block">{admission.primaryProcedureItemDescription}</strong>
                                    {/*/!* TODO: show Doctor name *!/*/}
                                    {/*<span className="add-info d-block">{admission.admittingDoctor}</span>*/}
                                </li>}
                                <li>
                                    <strong className="title">Funding</strong>
                                    {admission.isPrivateInsurance &&
                                        <dl className="person-list">
                                            <strong className="sub-heading d-block">Private Health</strong>
                                            <dt>{admission.fundCompleteTitle}</dt>
                                            <dd>&nbsp;</dd>
                                            <dt>Member No.:</dt>
                                            <dd>{admission.fundMemberNo ? admission.fundMemberNo : 'No Member No Assigned'}</dd>
                                        </dl>
                                    }
                                    {!admission.isPrivateInsurance &&
                                        <dl className="person-list">
                                            <strong className="sub-heading d-block">{admission.claimDescription}</strong>
                                        </dl>
                                    }
                                </li>
                                <li>
                                    <strong className="title">Patient Details</strong>
                                    <strong className="sub-heading d-block">DOB - {admission.DOB} <span className="age">(Age {admission.age})</span></strong>
                                    <span className="add-info d-block">{admission.address}</span>
                                </li>
                                <li>
                                    <strong className="title">Contact</strong>
                                    <dl className="contact-list">
                                        {admission.mobilePhone &&
                                        <>
                                            <dt><i className="icon ico-phone"/></dt>
                                            <dd><a href={`tel:${admission.mobilePhone}`}>{admission.mobilePhone}</a></dd>
                                        </>
                                        }
                                        {admission.email &&
                                            <>
                                                <dt><i className="icon ico-letter"/></dt>
                                                <dd><a href={`mailto:${admission.email}`}>{admission.email}</a></dd>
                                            </>
                                        }
                                    </dl>
                                </li>
                                <DocumentList />
                                <Admission_DevInfo_OechCheckData />
                            </ul>
                        </aside>
                    </div>
                    <div className="col-9">
                        <div className="main-content">
                            {renderProgressBlocks()}
                            <EventLogs eventTypes={admission.logs_eventTypes} logs={admission.logs}/>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Admission;