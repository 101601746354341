import React from "react";
import {useSelector, useDispatch} from "react-redux";

import loadingGif from '../../../../assets/images/loader.gif';
import * as actionTypes from "../../../../store/actions/actionTypes";

const ArchiveAdmissionDocumentsLink = ({admission}) => {
    const dispatch = useDispatch();
    const {archiveAdmissionDocumentsIds} = useSelector(state => state.admission);
    const {role} = useSelector(state => state.auth);

    const showArchiveModal = () => dispatch({type: actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS_SHOW, admission});

    if (!admission.haveDocuments || !role.canArchiveAdmissionDocuments) {
        return null;
    } else if(archiveAdmissionDocumentsIds.indexOf(admission.id) > -1) {
        return (
            <a className="dropdown-item disabled pointer"><img src={loadingGif} alt="pointer" />Archive Admission Documents</a>
        );
    } else {
        return (
            <a className="dropdown-item pointer" onClick={showArchiveModal}>Archive Admission Documents</a>
        );
    }
}

export default ArchiveAdmissionDocumentsLink;