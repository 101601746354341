import * as actionTypes from './actionTypes';
import axios from 'axios';
import LogRocket from 'logrocket';

export const verifyUser = (idToken) => {
    return (dispatch, getState) => {
        console.log('[actions/auth] verifyUser');
        dispatch({type: actionTypes.AUTH_SET_TOKEN, idToken: idToken});

        axios.get(
            `/verify`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                if (response.data.hospitals) {
                    LogRocket.identify(response.data.email);
                    if (response.data.hospitals.length) {
                        dispatch({type: actionTypes.AUTH_IDENTIFY_USER, ...response.data});
                    } else {
                        dispatch({type: actionTypes.LOADING_DONE});
                        dispatch({type: actionTypes.AUTH_IDENTIFY_BLOCKED_USER, ...response.data});
                    }
                }
            });
    }
}