import React from 'react';
import {useDispatch} from "react-redux";

import { sendRegMed} from "../../../../store/actions/customUI";
import * as actionTypes from "../../../../store/actions/actionTypes";
import {showAdmRegDiff} from "../../../../store/actions/modal";

// ACA-638
// This code is a mess. From what I see - I may never clean up it :(
// If you are trying to sort it out - sorry for this code.
// Today I need to complete weekly change set amount. Again.
// Permanent deadlines, permanent context switching with tons of the minor tasks in completely different areas.
// now I am burned out and I need to restore.
//
// Be careful, do not repeat my mistakes.

const AdmissionList_Item_ManagerCustomUI_SendRegMed = ({admission, customUI}) => {
    const dispatch = useDispatch();
    const doSend = () => {
        dispatch({type: actionTypes.MODAL_CONFIRM_ADMISSION_ACTION_SHOW, admission: admission, actionLabel: 'Send registration and medical history forms', actionDispatchable: sendRegMed(admission, customUI)})
    }

    const uiData = admission.manager_customUI[customUI.id];
    if(!uiData) {
        return;
    }

    if(!uiData.available) {
        return <td colSpan={2}><button className="btn btn-group-table-button btn-secondary">-</button></td>
    }

    if(!uiData.sent) {
        return <td colSpan={2}><button className="btn btn-group-table-button btn-dark-gray send-reg-btn" onClick={doSend}>{customUI.btn_title}</button></td>
    }

    const buttonWithStatus = (flag, largeBtn = false, showScore = false) => {
        if (!flag) {
            return <button type="button" className="btn-group-table-button btn btn-gray10">!</button>
        }
        if (flag.overloadedBy) {
            const showPdf = () => {
                dispatch({
                    type: actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_SHOW,
                    admissionDocument: actionTypes.ADMISSION_DOCUMENT,
                    admission: admission,
                    customUIStatus: flag,
                    managerCustomUi: customUI
                });
            }
            return <button type="button" className={`btn-pink btn-group-table-button btn btn-success10 text-success ${largeBtn ? 'large-btn' : ''}`} onClick={showPdf}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-file-pdf" viewBox="0 0 16 16">
                    <path
                        d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    <path
                        d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                </svg>
            </button>;
        }
        const showPdf = () => {
            if (showScore) {
                dispatch({
                    type: actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_SHOW,
                    admissionDocument: actionTypes.ADMISSION_DOCUMENT,
                    admission: admission,
                    customUIStatus: flag,
                    managerCustomUi: customUI,
                    jmphMedForm: true, // ACA-638 - clean up dirty hack
                });
            } else {
                dispatch(showAdmRegDiff(admission, '/reg-diff'));
            }
        }

        switch (flag.status) {
            case "STATUS_COMPLETED":
                if (showScore) {
                    let rating = admission.clinicalFlags_summaryRating;
                    let scoreClass = '';
                    if (rating === undefined) {
                        scoreClass = "btn-gray10";
                    } else if(rating < 1) {
                        scoreClass = "btn-success-light10 text-success-light";
                    // } else if(rating < 6) {
                    //     scoreClass = "btn-warning10 text-warning";
                    } else {
                        scoreClass = "btn btn-danger10 text-danger";
                    }
                    return <button type="button" className={`btn-group-table-button btn ${scoreClass}`} onClick={showPdf}>
                        {rating || '-'}
                    </button>
                }

                return <button type="button" className="btn-group-table-button btn btn-success10 text-success" onClick={showPdf}><span className="icon ico-checkmark"></span></button>;
            case "STATUS_VIEWED":
                return (
                    <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-blue pad046" data-tip={`Time viewed: ${flag.lastViewedAt}`} onClick={showPdf}>
                        <span className="icon ico-eye"></span>
                    </button>
                );
            case "STATUS_NEW":
                return (
                    // <button className="btn-pink btn-sm btn btn-success10 text-success"><img src={pdfSvg} /></button>
                    <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange pad046" data-tip={`Time sent: ${flag.createdAt}`} onClick={showPdf}>
                         <span className="icon ico-letter"></span>
                     </button>
                );
            default:
                return <button type="button" className="btn-sm btn btn-gray10">-</button>
        }
    }

    const documentButton = (doc, showScore = false) => {
        const signedByManagerDataTip = () => {
            if (!doc.signedByManager) {
                return null;
            }
            return `signed by ${doc.signedByManager}`;
        }

        return <td className={`${(doc.signedByManager) ? 'red-border' : ''}`} data-tip={signedByManagerDataTip()}>
            {buttonWithStatus(doc, false, showScore)}
        </td>
    }

    if (uiData.reg.overloadedBy && uiData.med.overloadedBy) {
        return <td colSpan="2">
            {buttonWithStatus(uiData.reg, true)}
        </td>
    }

    return <>
        {documentButton(uiData.reg)}
        {documentButton(uiData.med, true)}
    </>
}

export default AdmissionList_Item_ManagerCustomUI_SendRegMed;