import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside'; // Make sure to install this hook if not already


const getDocumentDate = (status, created_at) => {
    switch (status) {
        case 'Complete':
            return `Sent at ${created_at}`;
        case 'Pending':
            return '';
        case 'In Progress':
            return `Started at ${created_at}`;
        case 'Failed':
            return `Attempted at ${created_at}`;
        default:
            return ''; 
    }
};

const getBadgeClass = (status) => {
    switch (status) {
        case 'Pending':
            return 'badge-warning'; 
        case 'Complete':
            return 'badge-success';
        case 'In Progress':
            return 'badge-info'; 
        case 'Failed':
            return 'badge-danger'; 
        default:
            return 'badge-secondary'; 
    }
};

const statusMap = {
    'STATUS_DONE': 'Complete',
    'STATUS_SCHEDULED': 'Pending',
    'STATUS_EXECUTING': 'In Progress',
    'STATUS_FAILED': 'Failed'
};

const DocumentList = () => {
    const [documents, setDocuments] = useState([]);
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);
    const token = useSelector(state => state.auth.idToken);

    const fetchDocuments = async () => {
        try {
            const response = await axios.get('/lifehouse/admission/2/outgoing-api-call?page=1&per_page=4', {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'Host': 'admin.pep.local',
                }
            });
            setDocuments(response.data.payload.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [token]);

    const handleRetry = async (id) => {
        try {
            const response = await axios.post(`/lifehouse/admission/2/resend-outgoing-api-call/${id}`, {}, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'Host': 'admin.pep.local',
                }
            });
    
            // Check if response data contains success field
            if (response.data.success === true) {
                alert('Successfully resent document!');
                fetchDocuments(); 
            } else {
                alert('Failed to resend document.');
            }
        } catch (error) {
            alert('Failed to resend document.');
            console.error('Error retrying document:', error);
        }
    };
    

    const DocumentDropdown = ({ doc }) => {
        const dropdownRef = useRef(null);
        const [isOpen, setIsOpen] = useState(false);
        const toggleDropdown = () => setIsOpen(!isOpen);
        const closeDropdown = () => setIsOpen(false);

        useOnClickOutside(dropdownRef, closeDropdown);

        return (
            <div className="more-options-drop btn" ref={dropdownRef}>
                <div
                    onClick={toggleDropdown}
                    style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="5" r="2" fill="currentColor"/>
                        <circle cx="12" cy="12" r="2" fill="currentColor"/>
                        <circle cx="12" cy="19" r="2" fill="currentColor"/>
                    </svg>
                </div>
                {isOpen && (
                    <div className="dropdown-menu show dropdown-menu-right">
                        <span
                            className="dropdown-item"
                            onClick={() => handleRetry(doc.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            Resend
                        </span>
                    </div>
                )}
            </div>
        );
    };

    if (state_showDevUI) {
        return (
            <>
                <li style={{ backgroundColor: '#ffeeee' }}>
                    <strong className="title">OUTGOING API CALLS</strong>
                    {documents.map((doc, index) => {
                        const statusLabel = statusMap[doc.status_const] || doc.status_const;
                        
                        return (
                            <React.Fragment key={index}>
                                <div className="d-flex align-items-start mb-2">
                                    <div className="flex-grow-1">
                                        <div className="d-flex align-items-center">
                                            <dt className="me-2">{doc.title}</dt> 
                                            <dd className="mb-0" style={{ marginLeft: '10px' }}> 
                                                <span className={`badge ${getBadgeClass(statusLabel)}`}>
                                                    {statusLabel}
                                                </span>
                                            </dd>
                                        </div>
                                        <div className="mt-1">
                                            <dd className="mb-0">
                                                {getDocumentDate(statusLabel, doc.created_at)}
                                            </dd>
                                        </div>
                                    </div>
                                    {statusLabel === 'Failed' && (
                                        <DocumentDropdown doc={doc} />
                                    )}
                                </div>
                                <hr />
                            </React.Fragment>
                        );
                    })}
                </li>
            </>
        );
    }

    return null;
};

export default DocumentList;
