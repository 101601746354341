import * as actionTypes from "../actions/actionTypes";
import {AUTH_IDENTIFY_BLOCKED_USER, SET_DEV_UI_STATE} from "../actions/actionTypes";

const initialState = {
    verified: false,
    blockedUser: false,
    idToken: false,
    firstName: '',
    lastName: '',
    initials: '',
    email: '',
    hospitals: [],
    selectedHospital: {},
    devSmsTarget_enable: false,
    devSmsTarget_phone: '',
    manager_showOECDebugInfo: false,
    manager_patientImpersonate_enable: false,
    state_showDevUI: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DEV_UI_STATE:
            return {
                ...state,
                state_showDevUI: action.value
            }
        case actionTypes.AUTH_SET_TOKEN:
            return {
                ...state,
                idToken: action.idToken,
            }
        case actionTypes.AUTH_IDENTIFY_BLOCKED_USER:
            return {
                ...state,
                ...action,
                blockedUser: true,
                initials: action.firstName.substr(0,1)+action.lastName.substr(0,1),
                selectedHospital: action.defaultHospital ? action.defaultHospital : {...action.hospitals[0]},
            }
        case actionTypes.AUTH_SELECT_HOSPITAL:
            const selectedHospital = state.hospitals.find(hospital => hospital.id === parseInt(action.id));
            return {
                ...state,
                selectedHospital: {...selectedHospital},
            }
        case actionTypes.AUTH_IDENTIFY_USER:
            return {
                ...state,
                verified: true,
                ...action,
                // TODO: Remove from store
                initials: action.firstName.substr(0,1)+action.lastName.substr(0,1),
                selectedHospital: action.defaultHospital ? action.defaultHospital : {...action.hospitals[0]},
            }
        default:
            return state;
    }
}

export default reducer;
