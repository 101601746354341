import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import dotProp from 'dot-prop-immutable';

import * as actionTypes from "../../store/actions/actionTypes";

const ModalAdmissionTime_ExtraField = ({item}) => {
    const {admission, extraFields, selectedMessageTemplate} = useSelector(state => state.modal.admissionTime);
    const dispatch = useDispatch();

    const getValue = () => dotProp.get(extraFields, item.variable, item.defaultValue ? dotProp.get(admission, item.defaultValue, '') : '');
    const setValue = event => {
        dispatchValue(event.target.value);
        event.preventDefault();
    }
    const dispatchValue = value => dispatch({type: actionTypes.MODAL_ADMISSION_TIME_SET_EXTRA_FIELD, variable: item.variable, value: value});

    useEffect(() => {
        // console.log('[ModalAdmissionTime_ExtraField] useEffect ', item, getValue());
        if (item.defaultValue) { //  && !
            const _value = getValue();
            if (_value) {
                dispatchValue(_value)
            }
        }
    }, [selectedMessageTemplate]);

    return <div className={item.className}>
        {item.label}<br/>
        <input className="form-control" value={getValue()} onChange={setValue}/>
        {/*{selectedMessageTemplate.extraVariables} {admission.id}*/}
        {/*<input name="arrTime" className="form-control" onChange={handleArrTime} value={arrTime} />*/}
    </div>
}

export default ModalAdmissionTime_ExtraField;