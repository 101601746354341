import React, {useEffect, useState} from "react";
import useOnClickOutside from "use-onclickoutside";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useAuth0} from "../../../utils/react-auth0-spa";
import NavBar_DevUI from "./NavBar_DevUI";

const NavBar_UserMenu = () => {
    const {selectedHospital, initials, email, devUI_available} = useSelector(state => state.auth);
    const { logout } = useAuth0();

    const userPopupRef = React.useRef(null);
    const [userPopupVisible, setUserPopupVisible] = useState(false);
    useOnClickOutside(userPopupRef, () => setUserPopupVisible(false));

    const toggleUserPopupMenu = event => {
        setUserPopupVisible(!userPopupVisible);
        event.preventDefault();
    }

    useEffect(() => {
        setUserPopupVisible(false);
    }, [selectedHospital]);

    return (
        <div className="person-info-drop d-flex align-items-center" ref={userPopupRef}>
            <div
                className="avatar bg-primary text-white d-flex align-items-center justify-content-center">
                <a href="#" onClick={toggleUserPopupMenu} title={email}>
                    <span className="text-white">{initials}</span>
                </a>
            </div>
            <button type="button" className="dropdown-toggle dropdown-toggle-split"
                    id="dropdownMenuReference" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" data-reference="parent" onClick={toggleUserPopupMenu}>
                <span className="sr-only">Toggle Dropdown</span>
            </button>
            <div className={`dropdown-menu dropdown-menu-right${userPopupVisible ? ' show' : ''}`} aria-labelledby="dropdownMenuReference">
                {/*<a className="dropdown-item" href="#">Action</a>*/}
                {/*<a className="dropdown-item" href="#">Another action</a>*/}
                {devUI_available && <>
                    <NavBar_DevUI />
                    <div className="dropdown-divider" />
                </>
                }
                <Link to={`/admission/update-password`} className="dropdown-item">
                        Update Password
                </Link>
                <a className="dropdown-item" href="#" onClick={() => logout()}>Logout</a>
                
            </div>
        </div>
    );
}

export default NavBar_UserMenu;