import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import * as actionTypes from "../../../store/actions/actionTypes";

const NavBar_MainMenu = () => {
    const {selectedMenu, selectedMenu_subAction} = useSelector(state => state.main);
    const {selectedHospital, role} = useSelector(state => state.auth);

    // console.log('[NavBar_MainMenu]', selectedMenu);

    const isDashboard = () => selectedMenu === actionTypes.MENU_DASHBOARD;
    const isAdmissionList = () => selectedMenu === actionTypes.MENU_ADMISSIONS_LIST;
    const isDischargeList = () => selectedMenu === actionTypes.MENU_DISCHARGE_LIST;
    const isReport = () => selectedMenu === actionTypes.MENU_REPORTS;
    const isSettings = () => selectedMenu === actionTypes.MENU_SETTINGS;

    const doNothing = (event) => event.preventDefault();

    const link = (title, link, selected) => {
        if (selected) {
            if (selectedMenu_subAction) {
                return <li className="active"><Link to={link}>{title}</Link></li>;
            } else {
                return <li className="active"><a href="#" onClick={doNothing}>{title}</a></li>;
            }
        } else {
            return <li><Link to={link}>{title}</Link></li>; 
        }
    }

    return (
        <nav className="main-nav">
            <ul className="main-nav-list">
                {/*{link('Dashboard', '/dash', isDashboard())}*/}
                {link('Admissions List', '/admissions/', isAdmissionList())}
                {selectedHospital.manager_dischargeList_enable && role.canAccessDischargeList && link('Discharge List', '/discharge/', isDischargeList())}
                {/*{link('Reports', '/reports/', isReport())}*/}
                {/*{link('Settings', '/settings/', isSettings())}*/}
            </ul>
        </nav>
    );
}

export default NavBar_MainMenu;