import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import axios from 'axios';

import * as constants from "../../utils/constants";
import Spinner from "../Spinner";

const LifehouseInternalComments = ({admission, type=1}) => {
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState(true);
    const idToken = useSelector(state => state.auth.idToken);

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API_PATH}/lifehouse/admission/${admission.id}/comments/${type}`,
            {headers: {'Authorization': idToken}}
        )
        .then((response) => {
            console.log('[actions/modal] selectClinicalTeam reload admissions list');
            setComments(response.data.comments)
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Spinner />
    }
    const renderStatus = status => {
        switch (status) {
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PENDING:
                return (
                    <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange btn-25px">Pending</button>
                );
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_CLOSED:
                return (
                    <button type="button" className="btn-group-table-button btn btn-25px lifehouse-button-green">Closed</button>
                )
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PAC_IN_PROGRESS:
                return (
                    <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange btn-25px">In Progress</button>
                );
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PAC_REQUIRED:
                return (
                    <button type="button" className="btn-group-table-button btn btn-25px lifehouse-button-green">Required</button>
                )
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PAC_NOT_REQUIRED:
                return (
                    <button type="button" className="btn-group-table-button btn btn-25px lifehouse-button-red">Not required</button>
                )
        }
    }

    const renderComment = item => {
        return (
            <>
                <hr/>
                <div className="row">
                    <div className="col-6">
                        <strong>{item.body}</strong><br />
                        <i>{item.createdBy} {item.createdAt}</i>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        {renderStatus(item.status)}
                    </div>
                </div>
            </>
        )
    }

    return comments.map(renderComment);
}

export default LifehouseInternalComments;