import {forEach, size} from 'lodash';

export const parseAdmissionTemplateVars = (vars, template) => {
    let result = template;
    if (!vars.vars || !vars.values) {
        console.log('parseAdmissionTemplateVars - invalid vars', vars);
    } else {
        vars.vars.forEach((data, key) => {
            result = result.replace(data, vars.values[key]);
        })
    }
    if (size(vars.extraFields)) {
        forEach(vars.extraFields, (value, key) => {
            result = result.replace("{{"+key+"}}", value);
        });
    }

    return result;
}

export const lastWordInString = (str) => str.split(" ").splice(-1)[0];