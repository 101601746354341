import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import Admission_MhrImage from "../Lifehouse/Admission_MhrImage";
import Diff from "./Blocks/Diff";
import {useCustomLogic} from "../../utils/customLogic";

const ModalAdmissionRegDiff = () => {
    const dispatch = useDispatch();
    // TODO: ACA-638 - clean up dirty code - flag emrView is wrong way
    const {show, admission, data, emrView} = useSelector(state => state.modal.adm_reg_diff);
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());
    const {isCustomLogicJMPH} = useCustomLogic();

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADM_REG_DIFF_HIDE});

    const renderStatus = () => {
        switch (data.document.status) {
            case "STATUS_NEW":
                return (
                    <div className="medical-history-signed-at-block">Not viewed by patient</div>
                )
            case "STATUS_VIEWED":
            case "STATUS_NEXT":
            case "STATUS_CUTOFF":
                return (
                    <div className="medical-history-signed-at-block">Viewed by patient</div>
                )
            case "STATUS_COMPLETED":
                return (
                    <div className="medical-history-signed-at-block">Completed by {(data.document.signedByManager) ? 'staff member (' +data.document.signedByManager+')' : 'patient'} at {data.document.completedAt}</div>
                )
        }
    }

    const title = emrView ? 'EMR VIEW' : 'Admission Information';
    const row1Title = isCustomLogicJMPH() ? 'Original (Existed in PAS)' : 'Original (Existed in Meditech)';

    const renderModal = () => {
        if (show) {
            return <div ref={ref} className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body modal-cols-holder">
                        <div className="content-holder">
                            <div className="modal-header">
                                <h5 className="modal-title h1">{title} for {admission.name} {admission.surname}</h5>
                                {!isCustomLogicJMPH() && <Admission_MhrImage admission={admission} />}
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {!emrView && renderStatus()}

                            <div className="modal-admission-info-content scroll-600">
                                <Diff data={data} row1Title={row1Title} emrView={emrView}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    }

    return (
        <div className={`modal modal-med-history modal-single-column fade ${show ? 'show' : ''}`} id="modalAdmissionInfo" tabIndex="-1" role="dialog"
             style={{display: "block", paddingRight: "11px"}} ariaModal="true">
            {renderModal()}
        </div>
    )
}

export default ModalAdmissionRegDiff;