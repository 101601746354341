export const API_ERROR = 'API_ERROR';

export const DEV_SET_ADMISSIONS_LIST_PROFILER_URL = 'DEV_SET_ADMISSIONS_LIST_PROFILER_URL';

export const LOADING_START = 'LOADING_START';
export const LOADING_DONE = 'LOADING_DONE';

export const RELOADING_START = 'RELOADING_START';
export const RELOADING_DONE = 'RELOADING_DONE';

export const RELOADING_PROGRESS_START = 'RELOADING_PROGRESS_START';
export const RELOADING_PROGRESS_DONE = 'ADMISSIONS_LIST_RELOADING_PROGRESS_DONE';

export const RELOADING_CUSTOM_COLUMN_START = 'RELOADING_COVID_START';
export const RELOADING_CUSTOM_COLUMN_DONE = 'RELOADING_COVID_DONE';

// REFACTOR: WIP
export const ADVANCED_LIST_SET_DATE = 'ADVANCED_LIST_SET_DATE';
export const ADVANCED_LIST_SET_PAGE = 'ADVANCED_LIST_SET_PAGE';
export const ADVANCED_LIST_SET_GROUP = 'ADVANCED_LIST_SET_GROUP';
export const ADVANCED_LIST_SET_SORT = 'ADVANCED_LIST_SET_SORT';
export const ADVANCED_LIST_SET_KEYWORD = 'ADVANCED_LIST_SET_KEYWORD';
export const ADVANCED_LIST_SET = 'ADVANCED_LIST_SET';

export const DISCHARGE_LIST_SET_DATE = 'DISCHARGE_LIST_SET_DATE';
export const DISCHARGE_LIST_SET_PAGE = 'DISCHARGE_LIST_SET_PAGE';
export const DISCHARGE_LIST_SET_GROUP = 'DISCHARGE_LIST_SET_GROUP';
export const DISCHARGE_LIST_SET_SORT = 'DISCHARGE_LIST_SET_SORT';
export const DISCHARGE_LIST_SET_KEYWORD = 'DISCHARGE_LIST_SET_KEYWORD';
export const DISCHARGE_LIST_SET = 'DISCHARGE_LIST_SET';

export const ADMISSIONS_LIST_SET = 'ADMISSIONS_LIST_SET';
export const ADMISSIONS_LIST_SET_DATE = 'ADMISSIONS_LIST_SET_DATE';
export const ADMISSIONS_LIST_SET_KEYWORD = 'ADMISSIONS_LIST_SET_KEYWORD';
export const ADMISSIONS_LIST_SET_SORT = 'ADMISSIONS_LIST_SET_SORT';
export const ADMISSIONS_LIST_SET_GROUP = 'ADMISSIONS_LIST_SET_GROUP';
export const ADMISSIONS_LIST_SET_PAGE = 'ADMISSIONS_LIST_SET_PAGE';
// Not used - use dispatch(admissionList.load()); instead
// export const ADMISSIONS_LIST_RELOAD = 'ADMISSIONS_LIST_RELOAD';

export const ADMISSIONS_LIST_BULK_TOGGLE = 'ADMISSIONS_LIST_BULK_TOGGLE';
export const ADMISSIONS_LIST_BULK_SELECT_ALL = 'ADMISSIONS_LIST_BULK_SELECT_ALL';
export const ADMISSIONS_LIST_BULK_UNSELECT_ALL = 'ADMISSIONS_LIST_BULK_UNSELECT_ALL';

export const MODAL_ERROR_SHOW = 'MODAL_ERROR_SHOW';
export const MODAL_ERROR_HIDE = 'MODAL_ERROR_HIDE';

export const MODAL_PRESENTING_ILLNESS_CODES_SHOW = 'MODAL_PRESENTING_ILLNESS_CODES_SHOW';
export const MODAL_PRESENTING_ILLNESS_CODES_HIDE = 'MODAL_PRESENTING_ILLNESS_CODES_HIDE';

export const MODAL_ENABLE_PATIENT_PORTAL_SHOW = 'MODAL_ENABLE_PATIENT_PORTAL_SHOW';
export const MODAL_ENABLE_PATIENT_PORTAL_HIDE = 'MODAL_ENABLE_PATIENT_PORTAL_HIDE';
export const MODAL_ENABLE_PATIENT_PORTAL_LOADING = 'MODAL_ENABLE_PATIENT_PORTAL_LOADING';

export const MODAL_SELECT_DOCUMENT_SET_SHOW = 'MODAL_SELECT_DOCUMENT_SET_SHOW';
export const MODAL_SELECT_DOCUMENT_SET_TOGGLE = 'MODAL_SELECT_DOCUMENT_SET_TOGGLE';
export const MODAL_SELECT_DOCUMENT_SET_HIDE = 'MODAL_SELECT_DOCUMENT_SET_HIDE';

export const MODAL_DOCUMENT_STATUS_SHOW = 'MODAL_DOCUMENT_STATUS_SHOW';
export const MODAL_DOCUMENT_STATUS_HIDE = 'MODAL_DOCUMENT_STATUS_HIDE';

export const MODAL_OEC_STATUS_SHOW = 'MODAL_OEC_STATUS_SHOW';
export const MODAL_OEC_STATUS_HIDE = 'MODAL_OEC_STATUS_HIDE';

export const MODAL_OEC_STATUS_PRODA_SHOW = 'MODAL_OEC_STATUS_PRODA_SHOW';
export const MODAL_OEC_STATUS_PRODA_HIDE = 'MODAL_OEC_STATUS_PRODA_HIDE';

export const MODAL_CLINICAL_FLAGS_SHOW = 'MODAL_CLINICAL_FLAGS_SHOW';
export const MODAL_CLINICAL_FLAGS_HIDE = 'MODAL_CLINICAL_FLAGS_HIDE';

export const MODAL_LIFEHOUSE_ERFA_SHOW = 'MODAL_LIFEHOUSE_ERFA_SHOW';
export const MODAL_LIFEHOUSE_ERFA_HIDE = 'MODAL_LIFEHOUSE_ERFA_HIDE';

export const MODAL_ADM_REG_DIFF_SHOW = 'MODAL_ADM_REG_DIFF_SHOW';
export const MODAL_ADM_REG_DIFF_HIDE = 'MODAL_ADM_REG_DIFF_HIDE';

export const MODAL_ADMISSION_TIME_SHOW = 'MODAL_ADMISSION_TIME_SHOW';
export const MODAL_ADMISSION_TIME_HIDE = 'MODAL_ADMISSION_TIME_HIDE';
export const MODAL_ADMISSION_TIME_SET_ADM_TIME = 'MODAL_ADMISSION_TIME_SET_ADM_TIME';
export const MODAL_ADMISSION_TIME_SELECT_TEMPLATE = 'MODAL_ADMISSION_TIME_SELECT_TEMPLATE';

export const MODAL_ADMISSION_TIME_CUSTOMIZE = 'MODAL_ADMISSION_TIME_CUSTOMIZE';
export const MODAL_ADMISSION_TIME_CUSTOMIZE_HIDE = 'MODAL_ADMISSION_TIME_CUSTOMIZE_HIDE';
export const MODAL_ADMISSION_TIME_CUSTOMIZE_SELECT_TEMPLATE = 'MODAL_ADMISSION_TIME_CUSTOMIZE_SELECT_TEMPLATE';
export const MODAL_ADMISSION_TIME_RESTART = 'MODAL_ADMISSION_TIME_RESTART';
export const MODAL_ADMISSION_TIME_SET_EXTRA_FIELD = 'MODAL_ADMISSION_TIME_SET_EXTRA_FIELD';

export const MODAL_SEND_DOCUMENT_SET_COLUMN_SHOW = 'MODAL_SEND_DOCUMENT_SET_COLUMN_SHOW';
export const MODAL_SEND_DOCUMENT_SET_COLUMN_HIDE = 'MODAL_SEND_DOCUMENT_SET_COLUMN_HIDE';

export const MODAL_ADMISSION_DOCUMENT_PDF_SHOW = 'MODAL_ADMISSION_DOCUMENT_PDF_SHOW';
export const MODAL_ADMISSION_DOCUMENT_PDF_HIDE = 'MODAL_ADMISSION_DOCUMENT_PDF_HIDE';

export const MODAL_ADMISSION_UPLOAD_PDF_SHOW = 'MODAL_ADMISSION_UPLOAD_PDF_SHOW';
export const MODAL_ADMISSION_UPLOAD_PDF_HIDE = 'MODAL_ADMISSION_UPLOAD_PDF_HIDE';
export const MODAL_ADMISSION_UPLOAD_PDF_SET_FILE = 'MODAL_ADMISSION_UPLOAD_PDF_SET_FILE';
export const MODAL_ADMISSION_UPLOAD_APPROVING = 'MODAL_ADMISSION_UPLOAD_APPROVING';

export const MODAL_ADMISSION_VIEW_PDF_SHOW = 'MODAL_ADMISSION_VIEW_PDF_SHOW';
export const MODAL_ADMISSION_VIEW_PDF_HIDE = 'MODAL_ADMISSION_VIEW_PDF_HIDE';

export const MODAL_VIEW_ADMISSION_MESSAGE_SHOW = 'MODAL_VIEW_ADMISSION_MESSAGE_SHOW';
export const MODAL_VIEW_ADMISSION_MESSAGE_HIDE = 'MODAL_VIEW_ADMISSION_MESSAGE_HIDE';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_IDENTIFY_USER = 'AUTH_IDENTIFY_USER';
export const AUTH_IDENTIFY_BLOCKED_USER = 'AUTH_IDENTIFY_BLOCKED_USER';
export const AUTH_SELECT_HOSPITAL = 'AUTH_SELECT_HOSPITAL';

export const MENU_DASHBOARD = 'MENU_DASHBOARD';
export const MENU_ADMISSIONS_LIST = 'MENU_ADMISSIONS_LIST';
export const MENU_DISCHARGE_LIST = 'MENU_DISCHARGE_LIST';
export const MENU_REPORTS = 'MENU_REPORTS';
export const MENU_SETTINGS = 'MENU_SETTINGS';

export const ADMISSION_SET = 'ADMISSION_SET';
// ADMISSION_UPDATE cant be used instead of ADMISSION_SET, because it would overload admission after background action
export const ADMISSION_UPDATE = 'ADMISSION_UPDATE';

export const OEC_CLAIM_SET = 'OEC_CLAIM_SET';

export const DASHBOARD_SET_STATS = 'DASHBOARD_SET_STATS';

export const RESEND_SMS_START = 'RESEND_SMS_START';
export const RESEND_SMS_DONE = 'RESEND_SMS_DONE';
export const MODAL_RESEND_SMS_SHOW = 'MODAL_RESEND_SMS_SHOW';
export const MODAL_RESEND_SMS_HIDE = 'MODAL_RESEND_SMS_HIDE';

export const SEND_MESSAGE_START = 'SEND_MESSAGE_START';
export const SEND_MESSAGE_DONE = 'SEND_MESSAGE_DONE';
export const MODAL_SEND_MESSAGE_SHOW = 'MODAL_SEND_MESSAGE_SHOW';
export const MODAL_SEND_MESSAGE_HIDE = 'MODAL_SEND_MESSAGE_HIDE';

export const ARCHIVE_ADMISSION_DOCUMENTS_START = 'ARCHIVE_ADMISSION_DOCUMENTS_START';
export const ARCHIVE_ADMISSION_DOCUMENTS_DONE = 'ARCHIVE_ADMISSION_DOCUMENTS_DONE';
export const MODAL_ARCHIVE_ADMISSION_DOCUMENTS_SHOW = 'MODAL_ARCHIVE_ADMISSION_DOCUMENTS_SHOW';
export const MODAL_ARCHIVE_ADMISSION_DOCUMENTS_HIDE = 'MODAL_ARCHIVE_ADMISSION_DOCUMENTS_HIDE';
export const MODAL_ARCHIVE_CUSTOM_PDF_SHOW = 'MODAL_ARCHIVE_CUSTOM_PDF_SHOW';
export const MODAL_ARCHIVE_CUSTOM_PDF_HIDE = 'MODAL_ARCHIVE_CUSTOM_PDF_HIDE';

export const MODAL_CONFIRM_ADMISSION_ACTION_SHOW = 'MODAL_CONFIRM_ADMISSION_ACTION_SHOW';
export const MODAL_CONFIRM_ADMISSION_ACTION_HIDE = 'MODAL_CONFIRM_ADMISSION_ACTION_HIDE';


export const WEB_PAS_SYNC_START = 'WEB_PAS_SYNC_START';
export const WEB_PAS_SYNC_DONE = 'WEB_PAS_SYNC_DONE';

export const CLONE_TO_DEMO_START = 'CLONE_TO_DEMO_START';
export const CLONE_TO_DEMO_DONE = 'CLONE_TO_DEMO_DONE';

export const SUBFILTER_SET = 'SUBFILTER_SET';
export const SUBFILTER_RESET = 'SUBFILTER_RESET';

export const GENERATE_API_IFC_START = 'GENERATE_API_IFC_START';
export const GENERATE_API_IFC_DONE = 'GENERATE_API_IFC_DONE';

export const GENERATE_API_OEC_START = 'GENERATE_API_OEC_START';
export const GENERATE_API_OEC_DONE = 'GENERATE_API_OEC_DONE';

export const ADMISSION_DOCUMENT_COVID_SCREENING = 'covidScreen';
export const ADMISSION_DOCUMENT_MANAGER_CUSTOM_UI = 'customUI';
export const ADMISSION_DOCUMENT_ADMISSION_INFO = 'admissionInfo';
export const ADMISSION_DOCUMENT = 'admissionDocument';

export const MODAL_ADMISSION_MESSAGES_SHOW = 'MODAL_ADMISSION_MESSAGES_SHOW';
export const MODAL_ADMISSION_MESSAGES_HIDE = 'MODAL_ADMISSION_MESSAGES_HIDE';

export const MODAL_ADMISSION_DOCUMENTS_SHOW = 'MODAL_ADMISSION_DOCUMENTS_SHOW';
export const MODAL_ADMISSION_DOCUMENTS_HIDE = 'MODAL_ADMISSION_DOCUMENTS_HIDE';

export const MODAL_ADMISSION_RFA_SHOW = 'MODAL_ADMISSION_RFA_SHOW';
export const MODAL_ADMISSION_RFA_HIDE = 'MODAL_ADMISSION_RFA_HIDE';

export const MODAL_SEND_ADMISSION_DOCUMENT_SHOW = 'MODAL_SEND_ADMISSION_DOCUMENT_SHOW';
export const MODAL_SEND_ADMISSION_DOCUMENT_HIDE = 'MODAL_SEND_ADMISSION_DOCUMENT_HIDE';

export const MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_SHOW = 'MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_SHOW';
export const MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_HIDE = 'MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_HIDE';

export const MODAL_LIFEHOUSE_PAC_REQUIRED_SHOW = 'MODAL_LIFEHOUSE_PAC_REQUIRED_SHOW';
export const MODAL_LIFEHOUSE_PAC_REQUIRED_HIDE = 'MODAL_LIFEHOUSE_PAC_REQUIRED_HIDE';

export const MODAL_LIFEHOUSE_COMMENTS_SHOW = 'MODAL_LIFEHOUSE_COMMENTS_SHOW';
export const MODAL_LIFEHOUSE_COMMENTS_HIDE = 'MODAL_LIFEHOUSE_COMMENTS_HIDE';

export const MODAL_COMMENTS_SHOW = 'MODAL_COMMENTS_SHOW';
export const MODAL_COMMENTS_HIDE = 'MODAL_COMMENTS_HIDE';

export const HOSPITAL_PATIENT_SET = 'HOSPITAL_PATIENT_SET';

export const SET_DEV_UI_STATE = 'SET_DEV_UI_STATE';

export const MODAL_CUSTOM_UI_UPLOAD_PDF_SHOW = 'MODAL_CUSTOM_UI_UPLOAD_PDF_SHOW';
export const MODAL_CUSTOM_UI_UPLOAD_PDF_HIDE = 'MODAL_CUSTOM_UI_UPLOAD_PDF_HIDE';

export const MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_SHOW = 'MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_SHOW';
export const MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_HIDE = 'MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_HIDE';

export const MODAL_CUSTOM_UI_PRE_ADMISSION_SET_SHOW = 'MODAL_CUSTOM_UI_PRE_ADMISSION_SET_SHOW';
export const MODAL_CUSTOM_UI_PRE_ADMISSION_SET_HIDE = 'MODAL_CUSTOM_UI_PRE_ADMISSION_SET_HIDE';

export const MODAL_ARCHIVE_PATIENT_SHOW = 'MODAL_ARCHIVE_PATIENT_SHOW';
export const MODAL_ARCHIVE_PATIENT_HIDE = 'MODAL_ARCHIVE_PATIENT_HIDE';
