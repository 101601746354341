import React, {useState} from 'react';

const Diff = ({data, row1Title = "Original (Existed in Meditech)", row2Title = "Submitted by patient", field1 = 'eRFA', field2 = 'pep', emrView= false}) => {
    const renderGroup = group => {
        return (
            <>
                <h1>{group.title}</h1>
                <table>
                    <tr>
                        <td>&nbsp;</td>
                        <td className="mai-table-title">{row1Title}</td>
                        <td className="mai-table-title">{row2Title}</td>
                    </tr>
                    {group.items.map((item, key) => <Diff_Item title={item.title} value1={item[field1]} value2={item[field2]} key={key}/>)}
                </table>
            </>
        )
    }
    const renderGroupEmr = group => {
        return (
            <>
                <h1>{group.title}</h1>
                <table>
                    <tr>
                        <td colSpan={2}>&nbsp;</td>
                        <td className="mai-table-title">{row2Title}</td>
                    </tr>
                    {group.items.map((item, key) => <Diff_Item_Emr title={item.title} value={item[field2]} key={key}/>)}
                </table>
            </>
        )
    }

    if (emrView) {
        return data.diff.groups.map(renderGroupEmr);
    }
    return data.diff.groups.map(renderGroup);
}

export default Diff;

const Diff_Item = ({title, value1, value2, }) => {
    const [mouseOver, setMouseOver] = useState(false);

    const handleMouseOver = () => setMouseOver(true);
    const handleMouseOut = () => setMouseOver(false);

    const className1 = (value1 && (value1 !== value2)) ? 'mai-yellow' : "mai-none";

    const className2 = value2 ? (value1 ? ((value1 !== value2) ? 'mai-yellow' : "mai-none") : 'mai-green') : 'mai-none';
    return (
        <tr onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${mouseOver ? 'diff-grey-bg' : ''}`}>
            <td><span className="mai-none">{title}</span></td>
            <td><span className={className1}>{value1}</span></td>
            <td><span className={className2}>{value2}</span></td>
        </tr>
    )
}

const Diff_Item_Emr = ({title, value}) => {
    const [mouseOver, setMouseOver] = useState(false);

    const handleMouseOver = () => setMouseOver(true);
    const handleMouseOut = () => setMouseOver(false);
    return (
        <tr onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${mouseOver ? 'diff-grey-bg' : ''}`}>
            <td colSpan={2}><span className="mai-none">{title}</span></td>
            <td><span className={'mai-none'}>{value}</span></td>
        </tr>
    )
}