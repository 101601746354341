import React from 'react';

const JmphFundingType = ({type}) => {
    switch (type) {
        case "WOR":
        case "DVA":
        case "OTH":
        case "PRV":
            return <div className="lifehouse-color-code yellow" />
        case "UNI":
            return <div className="lifehouse-color-code pink" />
        case "MON":
            return <div className="lifehouse-color-code purple" />
        case "SWI":
            return <div className="lifehouse-color-code red" />
        default:
            return null;
    }
}

export default JmphFundingType;